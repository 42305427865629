import * as React from "react"
import { Layout } from "@bw/layouts"
import { PageHeader } from "@bw/modules"
import { Button, Section, Seo } from "@bw/bits"
import { useTranslation } from "react-i18next"

const NotFoundPage = ({ pageContext }) => {
  const { t } = useTranslation()
  return (
    <Layout {...{ pageContext }}>
      <Seo title={t("404.title")} />
      <Section>
        <PageHeader title={t("404.title")} />
        <Button to="/" label={t("404.button")} />
      </Section>
    </Layout>
  )
}

export default NotFoundPage
